import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Directory from "./pages/Directory";
import ContactUs from "./pages/ContactUs";
import WhatsOn from "./pages/WhatsOn";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Floorguide from "./pages/Floorguide";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/directory" element={<Directory />} />
          <Route path="/floorguide" element={<Floorguide />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/whats-on" element={<WhatsOn />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;
